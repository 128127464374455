import quotes from './quotes.json';
import './../scss/style.scss';

const getQuote = () => {
  const idx = Math.floor(Math.random() * quotes.length);
  const quote = quotes[ idx ];

  return [
    idx + 1,
    quotes.length,
    quote.text,
    quote.from,
  ];
}

const renderQuote = (doc) => {
  const wrapper = doc.createElement('div');
  wrapper.setAttribute('class', 'quote');

  const figure = wrapper.appendChild(
    doc.createElement('figure')
  );
  const quote = figure.appendChild(
    doc.createElement('blockquote')
  );

  const caption = figure.appendChild(
    doc.createElement('figcaption')
  );

  const paginator = figure.appendChild(
    doc.createElement('span')
  );

  const [idx, total, text, author] = getQuote();

  quote.textContent = `"${text}"`;
  caption.textContent = author;

  paginator.textContent = `# ${idx} / ${total}`;

  return wrapper;
}

const main = (doc) => {
  const body = doc.body;

  body.appendChild(renderQuote(doc));
};

(() => main(document))();
